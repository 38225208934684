// esta es la pagina de gracias de contacto
import React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"
import MisionVision from "../components/institucional/misionVision"
import NuestrosValores from "../components/institucional/nuestrosValores"
import Historia from "../components/institucional/historia"
import LineTime from "../components/institucional/lineTime"
import Img from "../assets/images/bgHeroInstitucional.jpg"
import { Helmet } from "react-helmet"
export default function Gracias() {
  return (
    <>
      {/* aqui podemos crear un componente de seo para que sea mas dinamica esta situacion */}
      <Helmet>
        <title>Institucional</title>
        <meta
          name="description"
          content="este es el gracias de los formularios de contactos"
        ></meta>
      </Helmet>
      <Layout>
        <Hero img={Img} title="Institucional" />
        <MisionVision />
        <NuestrosValores />
        <Historia />
        <LineTime />
      </Layout>
    </>
  )
}
