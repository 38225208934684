import React from "react"
import LogoSvgIsotipo from "../../logoIsoTipo"
import { MisionVision } from "./style"
const misionVision = () => {
  return (
    <MisionVision>
      <div
        data-aos="flip-down"
        data-aos-duration="1500"
        className="container card"
      >
        <div className="card__item card__item-mision">
          <LogoSvgIsotipo></LogoSvgIsotipo>
          <h2 className="card__title">MISI&Oacute;N</h2>
          <p className="card__text">
            Somos una organización industrial que diseña y desarrolla con
            ingenieria sus procesos de producción en la fabricación de
            carrocerias.
          </p>
        </div>
        <div className="card__item card__item-vision">
          <LogoSvgIsotipo></LogoSvgIsotipo>
          <h2 className="card__title">VISI&Oacute;N</h2>
          <p className="card__text">
            Al año 2022 queremos estar entre los 3 mejores fabricantes de
            carrocerias para ómnibus a nivel nacional y ser reconocidos por
            innovar en el diseño, proceso y gerencia empresarial.
          </p>
        </div>
      </div>
    </MisionVision>
  )
}
export default misionVision
