import React from "react"
import { NuestrosValores } from "./style"
import { Theme } from "../../../styles/layoutcss"
import Title from "../title"
import BastonDerecho from "../../../assets/images/bastonDerecho.png"
import BastonIzquierdo from "../../../assets/images/bastonIzquierdo.png"
import bgIsoTipo from "../../../assets/images/bgIsoTipo.svg"
const hero = () => {
  return (
    <NuestrosValores>
      <div
        data-aos="flip-up"
        data-aos-duration="1500"
        className="container card"
      >
        <Title color={Theme.colors.azul} title="NUESTROS VALORES" />
        <div className="card__wrapper">
          <div
            data-aos="flip-down"
            data-aos-duration="1500"
            className="card__item card__item-1"
          >
            <h3 className="card__subtitle">RESPONSABILIDAD</h3>
          </div>
          <div
            data-aos="fade-right"
            data-aos-duration="1500"
            className="card__item card__item-2"
          >
            <img
              className="card__Img card__Img-derecho"
              src={BastonDerecho}
              alt="baston decorativo"
            />
            <h3 className="card__subtitle">INNOVACIÓN</h3>
          </div>
          <div
            data-aos="fade-right"
            data-aos-duration="1500"
            className="card__item card__item-3"
          >
            <img
              className="card__Img card__Img-derechoMax"
              src={BastonDerecho}
              alt="baston decorativo"
            />
            <h3 className="card__subtitle">CONFIANZA</h3>
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="1500"
            className="card__item card__item-4"
          >
            <img
              className="card__Img card__Img-izquierdo"
              src={BastonIzquierdo}
              alt="baston decorativo"
            />
            <h3 className="card__subtitle">COMUNICACIÓN</h3>
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="1500"
            className="card__item card__item-5"
          >
            <img
              className="card__Img card__Img-izquierdoMax"
              src={BastonIzquierdo}
              alt="baston decorativo"
            />
            <h3 className="card__subtitle">MOTIVACIÓN</h3>
          </div>
          <div className="card__item card__item-6">
            <img
              className="card__item-6-Img"
              src={bgIsoTipo}
              alt="Logo Isotipo Intramet"
            />
          </div>
        </div>
      </div>
    </NuestrosValores>
  )
}
export default hero
